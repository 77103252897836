export default class TutorialHelp {
  constructor () {
    this.setInitialValue()
  }

  setInitialValue () {
    this.cover_image = ''
    this.coverImageUrl = null
    this.url = ''
    this.translations = [
      { id: null, heading: '', description: '', lang: 'ar' },
      { id: null, heading: '', description: '', lang: 'en' }
    ]
  }

  fillData (data) {
    if (data) {
      const itemAr = data.translations?.find(o => o.lang === 'ar')
      const itemEn = data.translations?.find(o => o.lang === 'en')

      this.coverImageUrl = data.cover_image ? data.cover_image : null
      this.url = data.url ? data.url : ''
      this.translations[0].id = itemAr ? itemAr.id : null
      this.translations[0].heading = itemAr ? itemAr.heading : ''
      this.translations[0].description = itemAr ? itemAr.description : ''
      this.translations[1].id = itemEn ? itemEn.id : null
      this.translations[1].heading = itemEn ? itemEn.heading : ''
      this.translations[1].description = itemEn ? itemEn.description : ''
    } else {
      this.setInitialValue()
    }
  }
}
