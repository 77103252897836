import api from "@/axios";

export default {
  findAll() {
    return api().get("tutorial-help");
  },
  findOne(id) {
    return api().get(`admin/tutorial-help/${id}`);
  },
  create(data) {
    return api().post("admin/tutorial-help/add", data);
  },
  update(id, data) {
    return api().patch(`admin/tutorial-help/${id}`, data);
  },
  remove(id) {
    return api().delete(`tutorial-help/${id}`);
  },
};
