<template>
  <div>
    <b-card>
      <ValidationObserver ref="tutorialHelp" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
        <b-row>
          <b-col md="12">
            <!-- <input-file v-model="tutorialHelp.cover_image" :defaultUrl="tutorialHelp.coverImageUrl" :name="$t('global.coverImage')" :label="$t('global.coverImage')" :validate="currentPage == itemPages.create ? '' : ''" :disabled="disableStatus" /> -->
            <cropper-images
                :label="$t('global.coverImage')"
                nameOfImage="image.jpg"
                @cropper-save="tutorialHelp.cover_image = $event.file ? $event.file : null"
                :multi="false"
                :imageUrl="tutorialHelp.coverImageUrl"
                :ratio="16/9"
                :disabled="disableStatus"
            />
          </b-col>

          <b-col md="6">
            <input-form v-model="tutorialHelp.translations[0].heading" :name="$t('global.headingAr')" :label="$t('global.headingAr')" validate="required" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="tutorialHelp.translations[1].heading" :name="$t('global.headingEn')" :label="$t('global.headingEn')" validate="required" :disabled="disableStatus" />
          </b-col>

          <b-col md="6">
            <textarea-form v-model="tutorialHelp.translations[0].description" :name="$t('global.descriptionAr')" :label="$t('global.descriptionAr')" validate="required" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <textarea-form v-model="tutorialHelp.translations[1].description" :name="$t('global.descriptionEn')" :label="$t('global.descriptionEn')" validate="required" :disabled="disableStatus" />
          </b-col>

          <b-col md="12">
            <input-form v-model="tutorialHelp.url" :name="$t('global.url')" :label="$t('global.url')" validate="required" :disabled="disableStatus" />
          </b-col>
        </b-row>

        <form-btns v-if="currentPage != itemPages.view" :label="submitLabel" :loading="loading" />
      </b-form>
      </ValidationObserver>
    </b-card>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import tutorialHelpServices from '../services/tutorialHelps'
import TutorialHelp from '../models/TutorialHelp'
import { objectToFormData } from '../../../../Utils/helper'
import moduleItemMixin from '../../../../Mixins/moduleItemMixin'

export default {
  mixins: [moduleItemMixin],
  data () {
    return {
      tutorialHelp: new TutorialHelp()
    }
  },
  methods: {
    async create () {
      const formData = objectToFormData(this.tutorialHelp)
      tutorialHelpServices.create(formData).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'generalSettings.tutorialHelps' })
      }).catch(() => {
        this.loading = false
      })
    },
    async update () {
      const formData = objectToFormData(this.tutorialHelp)
      tutorialHelpServices.update(this.id, formData).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'generalSettings.tutorialHelps' })
      }).catch(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (this.id) {
      tutorialHelpServices.findOne(this.id).then(response => {
        this.tutorialHelp.fillData(response.data.data)
        this.loading = false
      })
    } else {
      this.loading = false
    }
  }
}
</script>
